import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private oidcSecurityService: OidcSecurityService,
   ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return this.oidcSecurityService.getIdToken(localStorage.getItem('configId')).pipe(

      switchMap(token => {

        if(token && req.headers.has('Custom-Auth')){
          return  next.handle(req.clone({ setHeaders: { 'Custom-Auth': `Bearer ${token}` }}))
        }
        else{
          return  next.handle(req.clone())
        }

      }

    ));
    
  }
}
