<section class="page_container">
    <div class="intro">
        <img src="/assets/svg/logo-deeptech-white.svg" alt="logo deeptech" class="customised logo" />
        <p>Bienvenue dans votre <br>
            <span>Back-office Habilitations</span>
        </p>
        <span class="circles">
            <span *ngFor="let circle of [1, 2, 3];"></span>
        </span>
    </div>
    <div class="login">
        <p>Connectez vous</p>
        <a class="ds-btn ds-btn--primary" (click)="connect('isam')" >Collaborateur Bpifrance</a>
        <a class="ds-btn ds-btn--primary" (click)="connect('mcb')" >Utilisateur externe</a>
    </div> 
    <button (click)="logout()" class="btn-logout">
        <img src="/assets/images/icones/exit.svg" alt="icon logout" >
    </button>
</section>

<!-- TOASTER : error -->
<dep-toaster [status]="'error'" [toasterTitle]="'Accès refusé'"
  [toasterText]="'Vous n avez pas les droits nécessaires'" [show]="toasterFailure"
  (closeToaster)='closeToaster()'>
</dep-toaster>