import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as Hammer from 'hammerjs';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { MarkdownModule } from 'ngx-markdown';
import { BasicElementsModule } from '../components/basic-elements/basic-elements.module';
import { IconComponent } from './components/icon/icon.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';

export class MyHammerConfig extends HammerGestureConfig {
  override overrides = {
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL }
  } as any;
}
@NgModule({
  declarations: [
    // components
    IconComponent,

    // directives
    ClickOutsideDirective,

  ],
  imports: [
    CommonModule,
    FormsModule,
    BasicElementsModule,
    ReactiveFormsModule,

    RouterModule,
    MarkdownModule.forChild(),
    FeatureToggleModule,
    TranslateModule
  ],

  exports: [
    // components
    IconComponent,
    
    // directives
    ClickOutsideDirective,

    // modules
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig
    }
  ]
})
export class SharedModule { }
