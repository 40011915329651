import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserLevel } from 'src/app/models/user/user';
import { AuthService } from 'src/app/shared/services/authentification/auth.service';

@Component({
  selector: 'dep-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  toasterFailure: boolean;


  constructor(
    private titleService: Title,
    private meta: Meta,
    private authService: AuthService,
    private oidcService: OidcSecurityService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Backoffice - Connexion');
    this.meta.addTag({
      name: 'description',
      content:
        'Bienvenue dans votre Back-office Habilitations',
    });


    this.oidcService.checkAuth(null, sessionStorage.getItem('idp')).subscribe(
      {
        next: (value) => {
          // console.log("inside", value.isAuthenticated);
          if (value.isAuthenticated) {
            this.authService.getUserData().subscribe(
              {
                next: (v) => {
                  this.toasterFailure = !v.level;
                  if ([UserLevel.ADMIN, UserLevel.SUPER_ADMIN].find((level) => v.level == level) != null) {
                    this.router.navigateByUrl(localStorage.getItem("redirect"))
                  }
                }
              }
            );
          }
        },
        error: (err) => console.error(err)


      },

    );

  }

  connect(idp: string): void {
    this.authService.setRedirect("/dashboard-habilitations");
    localStorage.setItem('configId',idp);
    this.authService.login(idp);
    sessionStorage.setItem('idp', idp)
  }


  closeToaster(): void {
    this.toasterFailure = false;
  }

  logout() {
    this.authService.logout();
  }

}
