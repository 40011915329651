import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from 'src/app/environments/environment';

import { OidcSecurityService } from "angular-auth-oidc-client";
import { Observable } from "rxjs";
import { User } from "src/app/models/user/user";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private REST_API_SERVER = '';
    private USERS_URL = '/users'
    private USER_INFO = '/userInfo';


    public triggerFirstConnection = true;

    public user: User;


    constructor(
        public oidcSecurityService: OidcSecurityService,
        private router: Router,
        private http: HttpClient
    ) {
        this.REST_API_SERVER = environment.apiUrl;

    }



   


    getUserData(): Observable<User> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Custom-Auth': 'Bearer '
            })
        };
        
        return this.http.get<User>(this.REST_API_SERVER + this.USERS_URL + this.USER_INFO, httpOptions).pipe(map(user => Object.assign(new User(), user)));
    }

  
    login(configId?: string) {
        this.oidcSecurityService.authorize(configId);
    }


    logout() {

        this.oidcSecurityService.logoffAndRevokeTokens().subscribe(
            (result) => {
                this.user = null;
                localStorage.clear();
            }
        );
    }

    refuseAccess() {
        localStorage.setItem('popup', "true");
        localStorage.removeItem('redirect');
        this.navigateToHomePage()
        // this.logout();
    }


    public navigateToHomePage() {
        this.router.navigate([""]);
    }

    public setRedirect(url: string): void {
        localStorage.setItem('redirect', url);
    }

    public getRedirect(): string {
        let redirect = localStorage.getItem('redirect');
        localStorage.removeItem('redirect');
        return redirect;
    }

}