import { CommonModule } from '@angular/common';
import { NgModule, SecurityContext } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SelectMultipleComponent } from './select-multiple/select-multiple.component';

@NgModule({
  declarations: [
    SelectMultipleComponent,

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE, // turn off sanitization
    })
  ],
  exports: [
    SelectMultipleComponent,

    MatSelectModule,
    MatFormFieldModule,

  ],
  providers: [],
})
export class BasicElementsModule {}
