import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth-guard';

const routes: Routes = [
  {
    path:'login',
    loadChildren: () =>
      import('./components/view/login/login.module').then(
        (m) => m.LoginModule
      )
  },
  { 
    path: 'dashboard-habilitations',
    loadChildren: () =>
      import('./components/view/members-dashboard/members-dashboard.module').then
      (m => m.MembersDashboardModule),
      canActivate:[AuthGuard]
  },
  { path: 'dashboard-habilitations/archived-members',
    loadChildren: () =>
     import('./components/view/archived-members/archived-members.module').then
    (m => m.ArchivedMembersModule),
    canActivate:[AuthGuard]
  },
  { path: 'dashboard-habilitations/organizations',
   loadChildren: () =>
    import('./components/view/structures/structures.module').then
    (m => m.StructuresModule),
    canActivate:[AuthGuard]
   },
  { path: 'dashboard-habilitations/habilitations',
    loadChildren: () => 
    import('./components/view/habilitations/habilitations.module').then
    (m => m.HabilitationsModule),
    canActivate:[AuthGuard]
   }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
