import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { UserLevel } from "src/app/models/user/user";
import { AuthService } from "../services/authentification/auth.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    
    constructor(
        private authService: AuthService,
        private oidcSecurityService: OidcSecurityService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {



        return this.oidcSecurityService.isAuthenticated(sessionStorage.getItem("idp")).
            subscribe(isAuthenticated => {
                if (isAuthenticated) {
                    this.authService.getUserData().subscribe(
                        {
                            next: (v) => {
                                // console.log("user exist", v);
                                if (v.level == UserLevel.ADMIN || v.level == UserLevel.SUPER_ADMIN) {
                                    return true;
                                }else{
                                    return false;
                                }
                            },
                            error: (error) => {
                                console.error(error);                                
                                this.router.navigateByUrl("/login/.");
                                return false

                            }
                        }

                    );
                    return false;
                }
                else {
                    this.authService.setRedirect(state.url);
                    this.router.navigateByUrl("/login/.");
                    return false;
                }

            }
            )





    }


}

