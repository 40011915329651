import { NgModule, SecurityContext } from '@angular/core';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { MarkdownModule } from 'ngx-markdown';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BasicElementsModule } from '../basic-elements/basic-elements.module';
import { DropdownComponent } from './dropdown/dropdown.component';
import { PaginationSystemComponent } from './pagination-system/pagination-system.component';
import { PopupComponent } from './popup/popup.component';
import { ToasterComponent } from './toaster/toaster.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    //pipes

    //blocks
    DropdownComponent,
    PaginationSystemComponent,
    ToasterComponent
  ],
  imports: [
    BasicElementsModule,
    SharedModule,
    SlickCarouselModule,
    FeatureToggleModule,
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE, // turn off sanitization
    }),
    PopupComponent

  ],
  exports: [
    //pipes

    //blocks
    DropdownComponent,
    PaginationSystemComponent,
    PopupComponent,
    ToasterComponent
  ],
  providers: [],
})
export class BlocksModule { }
